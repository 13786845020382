// Generated by Framer (d31cd55)

import { addFonts, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {EK2RFzrSi: {hover: true}};

const serializationHash = "framer-GAKL3"

const variantClassNames = {EK2RFzrSi: "framer-v-wbvmf8"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "EK2RFzrSi", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} {...gestureHandlers} background={{alt: "", fit: "fill", loading: getLoadingLazyAtYPosition((componentViewport?.y || 0)), pixelHeight: 2000, pixelWidth: 1333, positionX: "left", positionY: "top", sizes: componentViewport?.width || "100vw", src: "https://framerusercontent.com/images/3WBls8kIYoYmGQGJlA3g0DVrnMM.jpg", srcSet: "https://framerusercontent.com/images/3WBls8kIYoYmGQGJlA3g0DVrnMM.jpg?scale-down-to=1024 682w,https://framerusercontent.com/images/3WBls8kIYoYmGQGJlA3g0DVrnMM.jpg 1333w"}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-wbvmf8", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"EK2RFzrSi"} ref={ref ?? ref1} style={{filter: "grayscale(1)", WebkitFilter: "grayscale(1)", ...style}} variants={{"EK2RFzrSi-hover": {filter: "grayscale(0)", WebkitFilter: "grayscale(0)"}}} {...addPropertyOverrides({"EK2RFzrSi-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-GAKL3.framer-glprp5, .framer-GAKL3 .framer-glprp5 { display: block; }", ".framer-GAKL3.framer-wbvmf8 { cursor: pointer; height: 155px; position: relative; width: 407px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 155
 * @framerIntrinsicWidth 407
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"xyFWwc5VS":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerz7MVz4LeJ: React.ComponentType<Props> = withCSS(Component, css, "framer-GAKL3") as typeof Component;
export default Framerz7MVz4LeJ;

Framerz7MVz4LeJ.displayName = "image";

Framerz7MVz4LeJ.defaultProps = {height: 155, width: 407};

addFonts(Framerz7MVz4LeJ, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})